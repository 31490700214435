



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// // import SideCategoryMenu from './SideCategoryMenu';
// import "../CSS/ProductDetailPage.css";
// import { FaFlag, FaTicketAlt } from 'react-icons/fa';
// import { addToCart } from '../Features/Slice';  // Import the action

// const ProductDetail = () => {
//   const { productId } = useParams();
//   const [product, setProduct] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [imageIndex, setImageIndex] = useState(0);
//   const [quantity, setQuantity] = useState(1); // Track product quantity for cart
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const fetchProduct = async () => {
//       setLoading(true);
//       Swal.fire({
//         title: 'Loading...',
//         text: 'Fetching product details, please wait.',
//         allowOutsideClick: false,
//         didOpen: () => {
//           Swal.showLoading();
//         },
//       });

//       try {
//         const response = await axios.get(`https://www.hotsalesng.com/api/get_product_by_id.php?productId=${productId}`);
//         if (response.data.success) {
//           setProduct(response.data.product);
//           Swal.close();
//         } else {
//           Swal.fire('Error', response.data.error, 'error');
//         }
//       } catch (error) {
//         Swal.fire('Error', 'There was an error fetching the product.', 'error');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProduct();
//   }, [productId]);

//   const handleAddToCart = () => {
//     const cartItem = {
//       id: product.id,
//       productName: product.product_name,
//       price: product.price,
//       quantity,
//       image: product.product_images[0],
//     };
//     dispatch(addToCart(cartItem));
//     Swal.fire('Success', 'Product added to cart', 'success');
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!product) {
//     return <div>Product not found.</div>;
//   }

//   return (
//     <div className='ProductDetailPageWrap'>
//       {/* <SideCategoryMenu /> */}
//       <div className='ProductDetailPageWrapA'>
//       <div className='ProductDetailPage'>
//         <div className='ProductDetailPageLeft'>
//           {product.product_images.map((image, index) => (
//             <img
//               key={index}
//               src={`https://www.hotsalesng.com/api/uploads/${image}`}
//               alt={`Product image ${index}`}
//               onClick={() => setImageIndex(index)}
//             />
//           ))}
//         </div>

//         <div className='ProductDetailPageMid'>
//           <img src={`https://www.hotsalesng.com/api/uploads/${product.product_images[imageIndex]}`} alt="Main Product" />
//         </div>

//         <div className='ProductDetailPageRight'>
//           <h2>{product.product_name}</h2>
//           <h4>Available Stock | {product.available_stock} - {product.available_stock <= 0 ? "Out of stock" : "In Stock"}</h4>

//           <div className='QualityAndCartButton'>
//             <button onClick={handleAddToCart}>${product.price} | Add to cart</button>
//             {/* <img src={require('../Images/Quality Badge2.png')} alt="quality Img" className='QualityBadge' /> */}
//           </div>

//           <div className='GuarranteeWrap'>
//             <p><FaTicketAlt style={{ color: "green" }} /> 1 Year 100% No-Risk Satisfaction Guarantee</p>
//             <p><FaFlag style={{ color: "red" }} /> Shipped from the USA</p>
//           </div>
//         </div>
//       </div>

//       {/* <img src={require('../Images/DetaiPageUsp.png')} alt="uspImg" className='DetailPageUspImg' /> */}
//       <h2 style={{ textAlign: "center", color: "#003366" }}>Product Description</h2>
//       <p style={{ textAlign: "center", padding: "2px",marginBottom:'100px' }}>{product.description}</p>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;


import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import "../CSS/ProductDetailPage.css";
import { FaFlag, FaTicketAlt } from 'react-icons/fa';
import { addToCart } from '../Features/Slice';
import SideCategoryMenu from './SideCategoryMenu';
import { buyNow } from '../Features/Slice';
import { Context } from './Context';
import DigitalDeliveryDetailPage from './DigitalDeliveryDetailPage';

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const {showDigitalDeliveryDetailPage,setShowDigitalDeliveryDetailPage}=useContext(Context)

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      Swal.fire({
        title: 'Loading...',
        text: 'Fetching product details, please wait.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const response = await axios.get(`https://www.hotsalesng.com/api/get_product_by_id.php?productId=${productId}`);
        if (response.data.success) {
          setProduct(response.data.product);
          Swal.close();
        } else {
          Swal.fire('Error', response.data.error, 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching the product.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleAddToCart = () => {
    const cartItem = {
      id: product.id,
      productName: product.product_name,
      price: product.price,
      quantity,
      image: product.product_images[0],
    };
    dispatch(addToCart(cartItem));
    Swal.fire('Success', 'Product added to cart', 'success');
  };


  
  const handleBuyNow = () => {

    const cartItem2 = {
        id: product.id,
        productName: product.product_name,
        price: product.price,
        image: product.product_images[0],
        url:product.product_url
    };
    dispatch(buyNow(cartItem2));
    Swal.fire({text:"",icon:"success",timer:1000,showConfirmButton:false});
    setShowDigitalDeliveryDetailPage(true)
    
};



  if (loading) return <div className="Loading">Loading...</div>;

  if (!product) return <div className="NoProduct">Product not found.</div>;

  return (
    <div className='ProductListpageContainer'>
      <div className='ProductDetailPageWrap'>
      <div className='ProductDetailPage'>
        <div className='ImageGallery'>
          {product.product_images.map((image, index) => (
            <img
              key={index}
              src={`https://www.hotsalesng.com/api/uploads/${image}`}
              alt={`Thumbnail ${index}`}
              onClick={() => setImageIndex(index)}
              className={index === imageIndex ? 'Thumbnail Active' : 'Thumbnail'}
            />
          ))}
        </div>
        <div className='ProductImage'>
          <img src={`https://www.hotsalesng.com/api/uploads/${product.product_images[imageIndex]}`} alt="Main Product" />
        </div>
        <div className='ProductDetails'>
          <h2 className="ProductName">{product.product_name}</h2>
          <p className="StockStatus">{product.available_stock > 0 ? "In Stock" : "Out of stock"}</p>
          {product.product_url!==null&&product.product_url!==""?<button className="AddToCartButton" onClick={handleBuyNow}>
            ₦{product.price} | Buy Now
          </button>:
          <button className="AddToCartButton" onClick={handleAddToCart}>
            ₦{product.price} | Add to cart
          </button>}
         
    
          <h3 className="DescriptionHeader">Product Description</h3>
          <p className="ProductDescription">{product.description}</p>
        </div>
      </div>
      
    </div>
    {showDigitalDeliveryDetailPage&&<DigitalDeliveryDetailPage/>}
    <SideCategoryMenu/>
    </div>
  );
};

export default ProductDetail;

