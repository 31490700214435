
// components/BestCategories.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTshirt, FaMobileAlt, FaHome, FaHamburger} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const fadeInRight = keyframes`
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
`;

const CategoriesSection = styled.section`
  padding: 4rem 2rem;
  background-color: #fff;
  color: rgba(0, 0, 255, 0.5);
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: rgba(0, 0, 255, 0.8);
  margin-bottom: 1.5rem;

`;

const SubText = styled.p`
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: #666;
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  animation: ${fadeInRight} 1s ease;
`;

const CategoryCard = styled.div`
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
  cursor:pointer;
  &:hover {
    transform: scale(1.05);
  }
`;

const CategoryIcon = styled.div`
  font-size: 3rem;
  color: rgba(0, 0, 255, 0.5);
  margin-bottom: 1rem;
`;

const CategoryName = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const CategoryDescription = styled.p`
  font-size: 1rem;
  color: #555;
  text-align: center;
`;

const BestCategories = () => {
  const navigate = useNavigate();


  return(
  <CategoriesSection>
    <Heading>Best Categories Just For You</Heading>
    <SubText>Explore our top categories and find your favorites.</SubText>
    <CategoriesGrid>
      <CategoryCard onClick={()=>navigate('/productlistpage/Fashion')}>
        <CategoryIcon><FaTshirt /></CategoryIcon>
        <CategoryName>Fashion</CategoryName>
        <CategoryDescription>Stylish, trendy, and comfortable. Redefine your wardrobe!</CategoryDescription>
      </CategoryCard>
      <CategoryCard onClick={()=>navigate('/productlistpage/Electronics')}>
        <CategoryIcon><FaMobileAlt /></CategoryIcon>
        <CategoryName>Electronics</CategoryName>
        <CategoryDescription>Discover the latest gadgets and top brands at unbeatable prices.</CategoryDescription>
      </CategoryCard>
      <CategoryCard onClick={()=>navigate('/productlistpage/Home Essentials')}>
        <CategoryIcon><FaHome /></CategoryIcon>
        <CategoryName>Home Essentials</CategoryName>
        <CategoryDescription>Make your house a home with our curated essentials.</CategoryDescription>
      </CategoryCard>
      <CategoryCard  onClick={()=>navigate('/productlistpage/Processed Foods')}>
  <CategoryIcon><FaHamburger /></CategoryIcon> {/* Icon that represents food */}
  <CategoryName>Processed Foods</CategoryName>
  <CategoryDescription>Explore a wide range of ready-to-eat and convenience foods to simplify your meals.</CategoryDescription>
</CategoryCard>
    </CategoriesGrid>
  </CategoriesSection>
)};

export default BestCategories;

