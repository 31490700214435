

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Context } from './Context';
import { updateFieldDigital } from '../Features/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Styled Components

// Full-screen container with semi-transparent background
const BackgroundOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:9;
`;

// Form container with responsive styling
const FormContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  color: rgba(0,0,255,0.6);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  outline:none;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  margin-top: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// React Component
const DigitalDeliveryDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DeliveryDetailDigital2=useSelector(state=>state.DeliveryDetailDigital2)
  console.log(DeliveryDetailDigital2)
  const {showDigitalDeliveryDetailPage,setShowDigitalDeliveryDetailPage}=useContext(Context);

  // Form data state
  const handleChange = (field, value) => {
    dispatch(updateFieldDigital({ field, value }));
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
  navigate('/ordersummarypagedigital');
  setShowDigitalDeliveryDetailPage(false);
  };

  return (
    <BackgroundOverlay>
      <FormContainer>
        <Title>PLEASE ENTER YOUR DETAILS</Title>
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={DeliveryDetailDigital2.firstName}
            onChange={(e)=>handleChange("firstName",e.target.value)}
            required
          />
          <Input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={DeliveryDetailDigital2.lastName}
            onChange={(e)=>handleChange("lastName",e.target.value)}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={DeliveryDetailDigital2.email}
            onChange={(e)=>handleChange("email",e.target.value)}
            required
          />
          <Input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={DeliveryDetailDigital2.phone}
            onChange={(e)=>handleChange("phone",e.target.value)}
            required
          />
          <SubmitButton type="submit">Proceed</SubmitButton>
          <SubmitButton type="button" 
          style={{backgroundColor:"white",border:"1px solid blue",color:"blue"}}
          onClick={()=>setShowDigitalDeliveryDetailPage(false)}>Cancel</SubmitButton>
        </form>
      </FormContainer>
    </BackgroundOverlay>
  );
};

export default DigitalDeliveryDetailPage;

