import React, { useContext } from 'react';
import '../CSS/Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Context } from './Context';
import logo from '../Images/logo.jpeg'
import logo2 from "../Images/logo.png"
import cac from "../Images/cac.jpeg"
import CacCertificate from './CacCertificate';

const Footer = () => {
  const navigate=useNavigate();
  const {subCategories,showCacCert,setShowCacCert}=useContext(Context);

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__about">
          <img src={logo2} alt="aboutimg"/>
          <h2>Hadassah Premium Stores</h2>
          <p>Your one-stop shop for premium products. We bring the best to your doorstep.</p>
          <p>Email: hadassahpremium247@gmail.com</p>
          <p>Phone: +234 816 886 4508</p>
          <p>Phone: +234 706 348 0314</p>
          <img src={cac} alt="aboutimg" style={{borderRadius:"0px",marginTop:"10px",cursor:"pointer"}}
          onClick={()=>setShowCacCert(true)}/>
          <p style={{cursor:"pointer",textDecoration:"underline"}}
          onClick={()=>setShowCacCert(true)}>View Certification</p>
        </div>

        <div className="footer__links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="" onClick={()=>navigate('/')}>Home</a></li>
            <li><a href="" onClick={()=>navigate('/aboutus')}>About Us</a></li>
            <li><a href="" onClick={()=>navigate('/contactus')}>Contact Us</a></li>
            <li><a href="" onClick={()=>navigate('/termsandconditions')}>Terms & Conditions</a></li>
            <li><a href="" onClick={()=>navigate('/privacypolicy')}>Privacy Policy</a></li>
            <li><a href="" onClick={()=>navigate('/returnandrefundpolicy')}>Return and Refund Policy</a></li>
          </ul>
        </div>

        <div className="footer__categories">
          <h3>Product Categories</h3>
          {subCategories.map((category)=>(
            <ul key={category.id}>
            <li><a onClick={()=>navigate(`/productlistpage/${category.name}`)}>{category.name}</a></li>
          </ul>
          ))}
        </div>

        {/* <div className="footer__newsletter">
          <h3>Join Our Newsletter</h3>
          <p>Stay updated with the latest products and offers.</p>
          <form className="newsletter__form">
            <input type="email" placeholder="Enter your email" className="newsletter__input" />
            <button className="newsletter__button">Subscribe</button>
          </form>
        </div> */}

<div className="footer__newsletter">
          <h3>Partners</h3>
          {/* <p>Stay updated with the latest products and offers.</p> */}
         <img src={logo} alt="partnerlogo"/>
        </div>

        <div className="footer__social">
          <h3>Follow Us</h3>
          <div className="social__icons">
            <a className="social__icon" onClick={()=>window.open("https://www.facebook.com/profile.php?id=61553102843471&mibextid=ZbWKwL","_blank")}><FaFacebookF /></a>
            {/* <a href="#twitter" className="social__icon"><FaTwitter /></a> */}
            <a className="social__icon" onClick={()=>window.open("https://www.instagram.com/hadassah.premium?igsh=YWxpOHE3Y2dhNXI4","_blank")}><FaInstagram /></a>
            {/* <a href="#youtube" className="social__icon"><FaYoutube /></a> */}
          </div>
        </div>
      </div>

      <div className="footer__copyright">
        <p>&copy; 2024 Hadassah Premium Stores. All Rights Reserved.</p>
        <p style={{fontSize:"0.7rem"}}>POWERED BY DIGITAL PREMIUM TECH</p>
      </div>
      {showCacCert&&<CacCertificate/>}
    </footer>
  );
};

export default Footer;
