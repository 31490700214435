import React, { useContext } from 'react'
import "../CSS/SideCategoryMenu.css"
import { useNavigate } from 'react-router-dom'
import { Context } from './Context'

const SideCategoryMenuMobile = () => {
const navigate = useNavigate()
const {setMobileMenuSwitch}=useContext(Context)
const {subCategories}=useContext(Context)



  return (
    <div className='SideCategoryMenu' 
    style={{display:"flex",
        width:"60%",
        position: "absolute",
        top: "0",
        right: "0",
        
        // justifyContent:"center",
        // alignItems:"center",
        backgroundColor:"#8080FF",

     
    }}>
      <p style={{fontWeight:"bold",fontSize:"1.2rem",textDecoration:"none",borderBottom:"none",cursor:"none",backgroundColor:"#8080FF",color:"white"}} className='pcategory1'>Product Categories</p>
      {/* <p style={{fontWeight:"bold",fontSize:"1.2rem",textDecoration:"none"}} className='pcategory1'>Hadassah Premium Brand</p> */}
      <p  className='pcategory1' onClick={() => {navigate("/productlistpage/All Product");setMobileMenuSwitch(false)}}>All Products</p>
      {subCategories.map((subCategory,index)=>(
        <p key={index} className='pcategory1'
         onClick={() => {navigate(`/productlistpage/${subCategory.name}`  );setMobileMenuSwitch(false)}}>{subCategory.name}</p>
      
      ))} 
    </div>

  )
}

export default SideCategoryMenuMobile
