    // PrivacyPolicy.js

import React from 'react';
import styled from 'styled-components';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      
      <Section>
        <Heading>1. Introduction</Heading>
        <Text>Hadassah Premium is committed to protecting your privacy. This policy outlines how we collect and handle your personal information.</Text>
      </Section>

      <Section>
        <Heading>2. Information Collection</Heading>
        <Text>We collect information such as name, email, and contact details when you register or make a purchase on our site.</Text>
      </Section>

      <Section>
        <Heading>3. Use of Information</Heading>
        <Text>Your information is used to provide and improve our services, fulfill orders, and keep you informed of new offers.</Text>
      </Section>

      <Section>
        <Heading>4. Data Protection</Heading>
        <Text>We implement strict security measures to protect your information and ensure it is not misused or accessed without authorization.</Text>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: rgba(0, 0, 255, 0.6);
  text-align: center;
`;

const Text = styled.p`
  color: #333;
  font-size: 1rem;
  margin: 15px 0;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Heading = styled.h2`
  color: rgba(0, 0, 255, 0.6);
  font-size: 1.2rem;
`;

export default PrivacyPolicy;
