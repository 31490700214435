// components/Hero.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import heroimg1 from "../Images/heroimg1a.png"

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const HeroSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${heroimg1});
  background-size:cover;
  background-position:center;
  text-align: center;
  color: white;
  animation: ${fadeIn} 1.5s ease-in;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Ensure content stays on top of the overlay */
  & > div {
    position: relative;
    z-index: 2;
  }
`;




const Heading = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const SubHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled.button`
  background-color: rgba(0, 0, 255, 0.5);
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Hero = () => (
  <HeroSection>
    <div>
      <Heading>Hadassah Premium Store</Heading>
      <SubHeading>Giving you Premium Delights</SubHeading>
      {/* <CTAButton>Shop Now</CTAButton> */}
    </div>
  </HeroSection>
);

export default Hero;
