import React, { useContext } from 'react'
import "../CSS/MenuCategories.css"
import { useNavigate } from 'react-router-dom'
import { FaHome } from 'react-icons/fa'
import { Context } from './Context'

const MenuCategories = () => {
  const navigate = useNavigate();
 const {subCategories}=useContext(Context);
  



  return (
    <div className='MenuCategories'>
        {subCategories.slice(0,5).map((subCategory)=>(
        <p  onClick={() => navigate(`/productlistpage/${subCategory.name}`)}>{subCategory.name}</p>
      
      ))} 
      
    </div>
  )
}

export default MenuCategories
