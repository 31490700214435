


// import React, { useEffect, useState, useContext, useRef } from 'react';
// import { FaCartPlus, FaHamburger, FaSearch, FaChevronRight, FaUserCircle } from "react-icons/fa";
// import "../CSS/Header.css";
// // import flag from "../Images/flag.png";
// import logo from "../Images/logo.png";
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// // import SideCategoryMenu2 from './SideCategoryMenu2';
// import { Context } from './Context';
// import Swal from 'sweetalert2';
// import axios from 'axios';

// const Header = () => {
//     const userInfo = useSelector(state => state.userInfo);
//     const navigate = useNavigate();
//     const cart = useSelector(state => state.cartItems);
//     const [totalQty, setTotalQty] = useState(0);
//     const totalAmount = useSelector(state => state.totalAmount);
//     const { mobileMenuSwitch, setMobileMenuSwitch, setSearchArray } = useContext(Context);
//     const sideMenuRef = useRef(null); // Reference for the side menu

//     // Calculate the total quantity of items in the cart
//     useEffect(() => {
//         const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
//         setTotalQty(totalItems);
//     }, [cart]);

//     // Add event listener to detect clicks outside of SideCategoryMenu2
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
//                 setMobileMenuSwitch(false); // Close the menu when clicking outside
//             }
//         };

//         if (mobileMenuSwitch) {
//             document.addEventListener('mousedown', handleClickOutside);
//         } else {
//             document.removeEventListener('mousedown', handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside); // Clean up listener on component unmount
//         };
//     }, [mobileMenuSwitch, setMobileMenuSwitch]);

//     const [searchInput, setSearchInput] = useState("");

//     const handleSearch = async () => {
//         const LoadingAlert = Swal.fire({ text: "Please wait..." });
//         Swal.showLoading();
//         try {
//             const response = await axios.get(`https://www.hotsalesng.com/api/get_products_by_name.php?searchTerm=${searchInput}`);
//             console.log(response.data);
//             if (response.data.success) {
//                 setSearchArray(response.data.products); // Set the search results in context
//                 navigate("/searchresultpage"); // Navigate to the results page
//             } else {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Oops...',
//                     text: response.data.error,
//                 });
//             }
//         } catch (error) {
//             console.error(error);
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Oops...',
//                 text: 'An error occurred while fetching products.',
//             });
//         } finally {
//             LoadingAlert.close();
//         }
//     };

//     return (
//         <div>
//             <div className='HeaderWrap1'>
//                 <img src={logo} alt="logo" onClick={() => navigate("/")} />
//                 <div className='HeaderCenterWrap1'>
                  
//                     <div className='SearchBarWrap1'>
//                         <input placeholder='Search Products' onChange={(e) => setSearchInput(e.target.value)} />
//                         <div className='SearchIconWrap1'>
//                             <FaSearch className='SearchIcon1' onClick={handleSearch} />
//                         </div>
//                     </div>
//                     <div className='HeaderUserArea1'>
//                         {!userInfo && <p onClick={() => navigate("/userlogin")}>Sign In / Join</p>}
//                         {!userInfo && <span onClick={() => navigate('/userdashboard')}>My Account <FaChevronRight className='HeaderUserAreaIcon1' /></span>}
//                         {userInfo && <span onClick={() => navigate('/userdashboard')}>Hi, {userInfo.full_name.slice(0, 6)} <FaChevronRight className='HeaderUserAreaIcon1' /></span>}
//                     </div>
//                 </div>
//                 <div className='HeaderCartImgWrap1' onClick={() => navigate("/cartpage")}>
//                     <div style={{ display: "flex" }}>
//                         <FaCartPlus className='HeaderCartIcon1' /> <p>({totalQty})</p>
//                     </div>
//                     <p>${totalAmount.toFixed(2)}</p>
//                 </div>
//             </div>
//             <div className='HeaderWrap2'>
//                 <div className='HeaderWrap2Up'>
//                     <FaHamburger className='FaBurger' onClick={() => setMobileMenuSwitch(true)} />
//                     {mobileMenuSwitch && (
//                         <div ref={sideMenuRef}>
//                             <SideCategoryMenu2 />
//                         </div>
//                     )}
//                     <img src={logo} alt="logo" onClick={() => navigate("/")} className='MobileLogo' />
//                     <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }} onClick={() => navigate("/userdashboard")}>
//                         <FaUserCircle className='FaUserCircle' /> {userInfo && <p>Hi, {userInfo.full_name?.slice(0, 1)}</p>}
//                     </div>
//                     <div className='HeaderCartImgWrap1' style={{ marginRight: "0px" }} onClick={() => navigate("/cartpage")}>
//                         <div style={{ display: "flex" }}>
//                             <FaCartPlus className='HeaderCartIcon1' /> <p>({totalQty})</p>
//                         </div>
//                         <p>${totalAmount.toFixed(2)}</p>
//                     </div>
//                 </div>
//                 <div className='HeaderWrap2Down'>
//                     <div className='SearchBarWrap2'>
//                         <input placeholder='Search Products' onChange={(e) => setSearchInput(e.target.value)} />
//                         <div className='SearchIconWrap2'>
//                             <FaSearch className='SearchIcon2' onClick={handleSearch} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Header;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { FaCartPlus, FaHamburger, FaSearch, FaChevronRight, FaHome } from "react-icons/fa";
import '../CSS/Header.css';
import logo from '../Images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Context } from './Context';
import Swal from 'sweetalert2';
import axios from 'axios';
import SideCategoryMenuMobile from './SideCategoryMenuMobile';

const Header = () => {
    const navigate = useNavigate();
    const cart = useSelector(state => state.cartItems);
    const totalAmount = useSelector(state => state.totalAmount);
    const { mobileMenuSwitch, setMobileMenuSwitch, setSearchArray,
        searchInput, setSearchInput,searchTitle,setSearchTitle } = useContext(Context);
    const [totalQty, setTotalQty] = useState(0);
    
    const sideMenuRef = useRef(null); 


    useEffect(() => {
        const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQty(totalItems);
    }, [cart]);

    const handleSearch = async () => {
        Swal.fire({ text: "Searching...", timer: 1000 });
        try {
            const response = await axios.get(`https://www.hotsalesng.com/api/get_products_by_name.php?searchTerm=${searchInput}`);
            if (response.data.success) {
                setSearchArray(response.data.products);
                navigate("/searchresultpage");
                setSearchInput("");
            } else {
                Swal.fire('Error', response.data.error, 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred while fetching products.', 'error');
        }
    };


        // Add event listener to detect clicks outside of SideCategoryMenu2
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
                setMobileMenuSwitch(false); // Close the menu when clicking outside
            }
        };

        if (mobileMenuSwitch) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Clean up listener on component unmount
        };
    }, [mobileMenuSwitch, setMobileMenuSwitch]);


    return (
        <header className="header">
            <div className="header__wrapper">
                <div className='HeaderLogoWrap' onClick={() => navigate("/")}>
                <img src={logo} alt="logo" className="header__logo"  />
                <h4>HADASSAH PREMIUM</h4>
                </div>

                <div className="header__search">
                    <input
                        type="text"
                        className="header__search-input"
                        placeholder="Search Products..."
                        value={searchInput}
                        onChange={(e) => {setSearchInput(e.target.value);setSearchTitle(e.target.value)}}
                    />
                    <button className="header__search-btn" onClick={handleSearch}>
                        <FaSearch />
                    </button>
                </div>
                <div className='header__home__icon' onClick={()=>navigate("/")}>
                <FaHome/> Home
                </div>

                <div className="header__cart" onClick={() => navigate("/cartpage")}>
                    <FaCartPlus className="header__cart-icon" />
                    <span className="header__cart-qty">({totalQty})</span>
                    <span className="header__cart-amount">₦{totalAmount.toFixed(2)}</span>
                </div>

                <FaHamburger className="header__hamburger" onClick={() => setMobileMenuSwitch(true)} />
            </div>

            {mobileMenuSwitch && (
              
                    <div ref={sideMenuRef}>
                        <SideCategoryMenuMobile/> 
                    </div>
           
            )}
        </header>
    );
};

export default Header;
