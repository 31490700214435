import React from 'react'
import SideCategoryMenu from './SideCategoryMenu'
import '../CSS/LandingPage.css'
import Hero from './Hero'
import HotDeals from './HotDeals'
import BestCategories from './BestCategories'
// import LatestProducts from './LatestProducts'
import HadassahPremiumBrand from './HadassahPremiumBrand'
import LatestProductsList from './LatestProductList'

const LandingPage = () => {
  return (
    <div className='LandingPageWrap'>
      <div className='LandingPage'>
        <Hero/>
      <HotDeals/>
      <BestCategories/>
      <LatestProductsList/>
      {/* <LatestProducts/> */}
      <HadassahPremiumBrand/>
    </div>  
    <SideCategoryMenu/>
    </div>
  )
}

export default LandingPage
