// components/HadassahPremiumBrand.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import premiumBackground from '../Images/footerbg2.png'; // Background image for the section

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PremiumSection = styled.section`
  padding: 5rem 2px;
  background: url(${premiumBackground}) center/cover no-repeat;
  position: relative;
  color: #fff;
  text-align: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const Heading = styled.h2`
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
`;

const SubHeading = styled.p`
  font-size: 1.3rem;
  color: #ddd;
  margin-bottom: 2rem;
`;

const FeaturesGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const FeatureCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
    @media(max-width:428px){
      width:150px;
    }
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  color: rgba(255, 215, 0, 0.8); /* Gold-like color for a premium feel */
  margin-bottom: 1rem;
`;

const FeatureText = styled.p`
  font-size: 1rem;
  color: #ccc;
`;

const HadassahPremiumBrand = () => (
  <PremiumSection>
    <Overlay />
    <Content>
      <Heading>Hadassah Premium Brand</Heading>
      <SubHeading>Experience luxury like never before. Quality, elegance, and exclusivity define us.</SubHeading>
      <FeaturesGrid>
        <FeatureCard>
          <FeatureIcon>🌟</FeatureIcon>
          <FeatureText>Exquisite Craftsmanship</FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>💎</FeatureIcon>
          <FeatureText>Luxurious Materials</FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>👗</FeatureIcon>
          <FeatureText>Exclusive Designs</FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>🌍</FeatureIcon>
          <FeatureText>Global Standards</FeatureText>
        </FeatureCard>
      </FeaturesGrid>
    </Content>
  </PremiumSection>
);

export default HadassahPremiumBrand;
