// TermsAndConditions.js

import React from 'react';
import styled from 'styled-components';

const TermsAndConditions = () => {
  return (
    <Container>
      <Title>Terms and Conditions</Title>
      
      <Section>
        <Heading>1. Introduction</Heading>
        <Text>Welcome to Hadassah Premium. By accessing our website, you agree to abide by the terms and conditions outlined here.</Text>
      </Section>

      <Section>
        <Heading>2. Product Use and Pricing</Heading>
        <Text>All products are subject to availability. Prices are subject to change without notice.</Text>
      </Section>

      <Section>
        <Heading>3. User Account</Heading>
        <Text>Users are responsible for maintaining the confidentiality of their accounts and password information.</Text>
      </Section>

      <Section>
        <Heading>4. Refunds and Returns</Heading>
        <Text>Refunds and returns are subject to our company’s policy, as described in our return policy.</Text>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: rgba(0, 0, 255, 0.6);
  text-align: center;
`;

const Text = styled.p`
  color: #333;
  font-size: 1rem;
  margin: 15px 0;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Heading = styled.h2`
  color: rgba(0, 0, 255, 0.6);
  font-size: 1.2rem;
`;

export default TermsAndConditions;
