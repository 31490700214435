
import React, { useState, useEffect, useContext } from 'react';
import "../CSS/ProductListPage.css";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addToCart } from '../Features/Slice';
import Swal from 'sweetalert2';
import SideCategoryMenu from './SideCategoryMenu';
import { buyNow } from '../Features/Slice';
import { Context } from './Context';
import DigitalDeliveryDetailPage from './DigitalDeliveryDetailPage';

const LatestProductsList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const {showDigitalDeliveryDetailPage,setShowDigitalDeliveryDetailPage}=useContext(Context);
    // const category = location.state?.category || "All Products";
    const category = "All Product";
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        const fetchProductsByCategory = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://www.hotsalesng.com/api/get_products_by_category.php?category=${category}`);
                setProducts(response.data.products || []);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProductsByCategory();
    }, [category]);

    const handleAddToCart = (id) => {
        const product = products.find((e) => e.id === id);
        const cartItem = {
            id: product.id,
            productName: product.product_name,
            price: product.price,
            quantity,
            image: product.product_images[0],
        };
        dispatch(addToCart(cartItem));
        Swal.fire('Success', 'Product added to cart', 'success');
    };


    
    const handleBuyNow = (id) => {
        const product = products.find((e) => e.id === id);
        const cartItem2 = {
            id: product.id,
            productName: product.product_name,
            price: product.price,
            image: product.product_images[0],
            url:product.product_url
        };
        dispatch(buyNow(cartItem2));
        Swal.fire({text:"",icon:"success",timer:1000,showConfirmButton:false});
        setShowDigitalDeliveryDetailPage(true)
        
    };


    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className='ProductListpageContainer' style={{backgroundColor:"#f0f0f5",paddingBottom:"50px",paddingTop:"50px"}}>
            <div className="product-list-page">
            <h1 className="category-title" style={{fontSize:"2rem",marginBottom:"1.5rem",color:"rgba(0,0,255,0.8)",border:"none"}}>
            Discover the Latest Products</h1>
            <p style={{marginBottom:"20px",textAlign:"center"}}>Our newest arrivals, crafted to perfection. Find something fresh and trendy!</p>

            <div className="product-grid">
                {products.length > 0 ? (
                    products.sort((a,b)=>b.id-a.id).slice(0,8).map(product => (
                        <div className="product-card" key={product.id}>
                            <img src={`https://www.hotsalesng.com/api/uploads/${product.product_images[0]}`}
                                alt={product.product_name}
                                onClick={() => navigate(`/productdetail/${product.id}`)} />
                            <div className="product-details">
                                <p onClick={() => navigate(`/productdetail/${product.id}`)}>{product.product_name}</p>
                                <span>₦{product.price}</span>
                                {product.product_url!==null&&product.product_url!==""?  <button onClick={() => handleBuyNow(product.id)}>Buy Now</button>:
                                <button onClick={() => handleAddToCart(product.id)}>Add to Cart</button>}
                                
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="no-products">No products found for this category.</div>
                )}
            </div>
        </div>
              {showDigitalDeliveryDetailPage&&<DigitalDeliveryDetailPage/>  }
        </div>
    );
};

export default LatestProductsList;

