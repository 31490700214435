import React from 'react'
import "../CSS/SideCategoryMenu.css"
import { useNavigate } from 'react-router-dom'
import {useContext} from 'react'
import {Context} from "./Context"

const SideCategoryMenu = () => {
const navigate = useNavigate()

const {subCategories}=useContext(Context)


  return (
    <div className='SideCategoryMenu'>
      <p style={{fontWeight:"bold",fontSize:"1.2rem",textDecoration:"none",borderBottom:"none",cursor:"none",backgroundColor:"#8080FF",color:"white"}} className='pcategory1'>Product Categories</p>
      {/* <p style={{fontWeight:"bold",fontSize:"1.2rem",textDecoration:"none"}} className='pcategory1'>Hadassah Premium Brand</p> */}
      <p  className='pcategory1' onClick={() => navigate("/productlistpage/All Product")}>All Products</p>
      {subCategories.map((subCategory,index)=>(
        <p key={index} className='pcategory1'
         onClick={() => navigate(`/productlistpage/${subCategory.name}`  )}>{subCategory.name}</p>
      
      ))} 
    </div>

  )
}

export default SideCategoryMenu
