


import axios from 'axios';
import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { Context } from './Context';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
const VALID_IMAGE_TYPES = ['image/jpeg', 'image/png'];



function UploadProduct({setAdminMenu}) {
  const [selectedCategory,setSelectedCategory]=useState("")
  const {subCategories}=useContext(Context);
  const [productData, setProductData] = useState({
    productName: '',
    description: '',
    price: '',
    availableStock: '1',
    category: '',
    productUrl:'',
    productImages: []
  });

  console.log(productData)

  const [previewImages, setPreviewImages] = useState([]);


  const handleCategoryChange=(e)=>{
    setSelectedCategory(e.target.value)
    // handleInputChange(e);
  }

  const handleInputChange = (e) => {
    setProductData({
      ...productData,
      [e.target.name]: e.target.value,
      
    });


    // if([e.target.name]==="producturl"){
    //   setSelectedCategory(e.target.value);
    // }   

  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    
    if (productData.productImages.length + selectedFiles.length > 4) {
      Swal.fire('Error', 'You cannot upload more than 4 images', 'error');
      return;
    }

    const invalidFiles = selectedFiles.filter(file => 
      file.size > MAX_FILE_SIZE || !VALID_IMAGE_TYPES.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map(file => file.name).join(', ');
      Swal.fire('Error', `Invalid file types or sizes detected: ${invalidFileNames}`, 'error');
      return;
    }

    const newImages = selectedFiles.map((file) => URL.createObjectURL(file));
    setPreviewImages((prev) => [...prev, ...newImages]);
    setProductData((prev) => ({
      ...prev,
      productImages: [...prev.productImages, ...selectedFiles],
    }));
  };

  const handleRemoveImage = (indexToRemove) => {
    setPreviewImages((prev) => prev.filter((_, index) => index !== indexToRemove));
    setProductData((prev) => ({
      ...prev,
      productImages: prev.productImages.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('productName', productData.productName);
    formData.append('description', productData.description);
    formData.append('price', productData.price);
    formData.append('availableStock', productData.availableStock);
    formData.append('category', productData.category);
    formData.append('productUrl', productData.productUrl);
    productData.productImages.forEach((image) => formData.append('productImages[]', image));
  
    try {
      Swal.fire({
        title: 'Uploading...',
        text: 'Please wait',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      const response = await axios.post('https://www.hotsalesng.com/api/upload_product.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Logging the entire response for debugging
      console.log(response.data);
  
      if (response.data.success) {
        Swal.fire('Success', response.data.message, 'success');
               setAdminMenu(0)
      } else {
        // Improved error handling to log the error response
        console.error('Error Response:', response.data);
        Swal.fire('Error', response.data.error, 'error');
      }
    } catch (error) {
      console.error('Error during submission:', error);
      Swal.fire('Error', 'There was an error uploading the product.', 'error');
    }
  };
  

  return (
    <UploadFormWrap>
      <h2 style={{textAlign:"center",color:"rgba(0,0,255,0.5)"}}>Upload Product</h2>
      <UploadForm onSubmit={handleSubmit}>
        <Input type="text" name="productName" placeholder="Product Name" onChange={handleInputChange} required/>
        <Input type="text" name="description" placeholder="Description" onChange={handleInputChange} required/>
        <Input type="number" name="price" placeholder="Price" onChange={handleInputChange} required/>
        {/* <Input type="number" name="availableStock" placeholder="Available Stock" onChange={handleInputChange} /> */}
        <Select name="category" onChange={(e)=>{handleInputChange(e);handleCategoryChange(e)}} required>
          <option value="">--Select Category--</option>
          {subCategories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </Select>

        {selectedCategory==="Digital Products"&&<Input type="text" name="productUrl" placeholder="Enter Product Url" onChange={handleInputChange} required/>}
        
        <ImageUploadContainer>
          <input
            type="file"
            name="productImages"
            multiple
            accept="image/jpeg,image/png"
            onChange={handleFileChange}
            required
          />
        </ImageUploadContainer>
        <PreviewImagesContainer>
          {previewImages.map((image, index) => (
            <ImageWrap key={index}>
              <Image src={image} alt="preview" />
              <Button3 type="button" onClick={() => handleRemoveImage(index)}>
                Remove
              </Button3>
            </ImageWrap>
          ))}
        </PreviewImagesContainer>
        <SubmitButton type="submit">Upload Product</SubmitButton>
      </UploadForm>
    </UploadFormWrap>
  );
}

export default UploadProduct;

// Add styled-components for layout
const UploadFormWrap = styled.div`
  width: 100%;
  background-color: rgba(255,255,255,0.7);
  padding: 10px;
`;

const UploadForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 884px) {
    width: 100%;
  }
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline:none;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  background-color: rgba(0,0,255,0.5);
  color: white;
  padding: 10px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 50px;
`;

const ImageUploadContainer = styled.div``;

const PreviewImagesContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;



const ImageWrap = styled.div`
  width:70px;
  display:flex;
  flex-direction:column;
  height:80px;
  background-color:red;
`

const Image = styled.img`
  width:100%;
  height:100%;
`

const Button3 = styled.button`
  cursor:pointer;
`