// CenteredImageOverlay.js

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import cac from '../Images/cac.jpeg'
import { Context } from './Context';

const CacCertificate = () => {
    const {showCacCert,setShowCacCert}=useContext(Context)

  return (
    <OverlayContainer>
      <Image src={cac} alt="caccertificate" />
      <Button onClick={()=>setShowCacCert(false)}>Close</Button>
    </OverlayContainer>
  );
};

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction:column;
  gap:20px;
  padding-top:10px;
  padding-bottom:10px;
`;

const Image = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;


const Button = styled.button`
    padding:10px;
    background-color:white;
    color:blue;
    border:none;
    border-radius:5px;
    cursor:pointer;

    &:hover{
    font-weight:bold;
    }
`

export default CacCertificate;
