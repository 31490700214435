// AboutUs.js

import React from 'react';
import styled from 'styled-components';

const AboutUs = () => {
  return (
    <Container>
      <Title>About Us</Title>
      <Text>Hadassah Premium (BN:7196288) is a versatile business offering a wide range of products, including food items, home goods, fashion items, kitchen items, electronics, appliances, digital products, and more. We’re passionate about bringing quality products and services to our customers.</Text>
      

      <Section>
        <Heading>Our Mission</Heading>
        <Text>Our mission is to provide top-quality merchandise and services, ensuring our customers have access to the best in the market, from everyday essentials to specialized items.</Text>
      </Section>

      <Section>
        <Heading>What We Do</Heading>
        <Text>At Hadassah Premium, we manage everything from food processing and packaging to conducting training programs to help people learn essential skills. Our focus is on delivering value to our customers with a commitment to quality and satisfaction.</Text>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: rgba(0, 0, 255, 0.6);
  text-align: center;
`;

const Text = styled.p`
  color: #333;
  font-size: 1rem;
  margin: 15px 0;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Heading = styled.h2`
  color: rgba(0, 0, 255, 0.6);
  font-size: 1.2rem;
`;

export default AboutUs;
