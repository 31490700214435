
// import React, { useContext, useState, useEffect } from 'react';
// import "../CSS/ProductListPage.css";
// import SideCategoryMenu from './SideCategoryMenu';
// import { useNavigate } from 'react-router-dom';
// import { addToCart } from '../Features/Slice';
// import { useDispatch } from 'react-redux';
// import Swal from 'sweetalert2';
// import { Context } from './Context';

// const SearchResultPage = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { searchArray } = useContext(Context); // Access the search results from context
//     const [products, setProducts] = useState(searchArray || []); // Initialize products with searchArray
//     const [loading, setLoading] = useState(false);
//     const [quantity, setQuantity] = useState(1); // Track product quantity for cart

//     useEffect(() => {
//         // Update products when searchArray changes
//         setProducts(searchArray);
//     }, [searchArray]);

//     const AddToCart = (id) => {
//         const product = products.find((e) => e.id === id);
//         if (product) {
//             const cartItem = {
//                 id: product.id,
//                 productName: product.product_name,
//                 price: product.price,
//                 quantity,
//                 image: product.product_images[0],
//             };

//             dispatch(addToCart(cartItem));
//             Swal.fire('Success', 'Product added to cart', 'success');
//         }
//     };

//     if (loading) {
//         return <div style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>;
//     }

//     return (
//         <div className='ProductListPageWrapA'>
//             <SideCategoryMenu />
//             <div className='ProductListPageWrap'>
//                 <div className='ProductListPageTitleWrap' style={{display:"flex"}}>
//                     <p className='ProductListPageTitle'>Search Results</p>
//                     <p className='ProductListPageTitle'>{products.length} Results</p>
//                 </div>
//                 <div className='ProductCards'>
//                     {products.length > 0 ? (
//                         products.map(product => (
//                             <div className='ProductCard' key={product.id}>
//                                 <img 
//                                 src={`https://www.hotsalesng.com/api/uploads/${product.product_images[0]}`} 
//                                 alt={product.product_name} 
//                                 onClick={() => navigate(`/productdetail/${product.id}`)}
//                                 />

//                                 <div className='ProductTextWrap'>
//                                     <p onClick={() => navigate(`/productdetail/${product.id}`)}>
//                                         {product.product_name}
//                                     </p>
//                                     <span>${product.price}</span>
//                                     <div className='ButtonAndQtyWrap'>
//                                         {/* <input type="number" min="1" value={quantity} onChange={(e) => setQuantity(e.target.value)} /> */}
//                                         <button onClick={() => AddToCart(product.id)}>Add to cart</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))
//                     ) : (
//                         <div style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>No products found for this search.</div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SearchResultPage;

import React, { useContext, useState, useEffect } from 'react';
import "../CSS/ProductListPage.css"; // Ensure CSS styles match ProductListPage
import SideCategoryMenu from './SideCategoryMenu';
import { useNavigate } from 'react-router-dom';
import { addToCart } from '../Features/Slice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Context } from './Context';
import { buyNow } from '../Features/Slice';
import DigitalDeliveryDetailPage from './DigitalDeliveryDetailPage';

const SearchResultPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { searchArray ,searchInput,searchTitle,setShowDigitalDeliveryDetailPage,showDigitalDeliveryDetailPage} = useContext(Context); // Access the search results from context
    const [products, setProducts] = useState(searchArray || []); // Initialize products with searchArray
    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(1); // Track product quantity for cart
  

    useEffect(() => {
        setProducts(searchArray); // Update products when searchArray changes
    }, [searchArray]);

    const handleAddToCart = (id) => {
        const product = products.find((e) => e.id === id);
        if (product) {
            const cartItem = {
                id: product.id,
                productName: product.product_name,
                price: product.price,
                quantity,
                image: product.product_images[0],
            };
            dispatch(addToCart(cartItem));
            Swal.fire('Success', 'Product added to cart', 'success');
        }
        
    };


    const handleBuyNow = (id) => {
        const product = products.find((e) => e.id === id);
        const cartItem2 = {
            id: product.id,
            productName: product.product_name,
            price: product.price,
            image: product.product_images[0],
            url:product.product_url
        };
        dispatch(buyNow(cartItem2));
        Swal.fire({text:"",icon:"success",timer:1000,showConfirmButton:false});
        setShowDigitalDeliveryDetailPage(true)
        
    };


    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className='ProductListpageContainer'>
            
            <div className="product-list-page">
              
                    <h1 className="category-title">({products.length}) Search Results for : <span style={{fontStyle:"italic"}}>{searchTitle}</span></h1>
        
    
                <div className="product-grid">
                    {products.length > 0 ? (
                        products.map(product => (
                            <div className="product-card" key={product.id}>
                                <img 
                                    src={`https://www.hotsalesng.com/api/uploads/${product.product_images[0]}`} 
                                    alt={product.product_name} 
                                    onClick={() => navigate(`/productdetail/${product.id}`)}
                                />
                                <div className="product-details">
                                    <p onClick={() => navigate(`/productdetail/${product.id}`)}>{product.product_name}</p>
                                    <span>₦{product.price}</span>
                                    {product.product_url!==null&&product.product_url!==""?<button onClick={() => handleBuyNow(product.id)}>Buy Now</button>:
                                    <button onClick={() => handleAddToCart(product.id)}>Add to Cart</button>}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="no-products">No products found for this search.</div>
                    )}
                </div>
            </div>
            <SideCategoryMenu />
            {showDigitalDeliveryDetailPage&&<DigitalDeliveryDetailPage/>}
        </div>
    );
};

export default SearchResultPage;
