import React, { useContext, useState } from 'react'
import { createContext } from 'react'

export const Context = createContext();

const ContextProvider = ({children}) => {
    const [adminToken,setAdminToken]=useState(null)
    const [admin,setAdmin]=useState(null)
    const [adminMenu,setAdminMenu]=useState(0) 
    const [trackingID, setTrackingID] = useState('');
    const [mobileMenuSwitch,setMobileMenuSwitch]=useState(false)
    const [searchArray,setSearchArray]=useState([])
    const [searchInput, setSearchInput] = useState("");
    const [searchTitle,setSearchTitle]=useState('')
    const [showDigitalDeliveryDetailPage,setShowDigitalDeliveryDetailPage]=useState(false);
    const [showCacCert,setShowCacCert]=useState(false)

const orderSendEmailUrl="https://hotsalesngonboarding.onrender.com/api/send-order-summary"


const subCategories = [
  { id: 14, name: 'Hadassah Premium Brand' },
  { id: 15, name: 'Electronics' },
  { id: 16, name: 'Phones and Accessories' },
  { id: 25, name: 'Digital Products' },
  { id: 17, name: 'Fashion' },
  { id: 18, name: 'Kitchen Items' },
  { id: 19, name: 'Home Essentials' },
  { id: 20, name: 'Cooking Oils' },
  { id: 21, name: 'Spices' },
  { id: 22, name: 'Processed Foods' },
  { id: 23, name: 'Grains' },
  { id: 24, name: 'Hot Deals' },
];



  return (
    <Context.Provider value={{adminToken,setAdminToken,admin,setAdmin,adminMenu,
    setAdminMenu,trackingID,setTrackingID,orderSendEmailUrl,mobileMenuSwitch,setMobileMenuSwitch,
    searchArray,setSearchArray,searchInput,setSearchInput,searchTitle,setSearchTitle,subCategories,
    showDigitalDeliveryDetailPage,setShowDigitalDeliveryDetailPage,showCacCert,setShowCacCert}}>
        {children}
    </Context.Provider>
      

  )
}

export default ContextProvider


// database details
// password : #Hps123hps
// User: hotsalesng_hps
// Database: hotsalesng_hps
