// ReturnRefundPolicy.js

import React from 'react';
import styled from 'styled-components';

const ReturnRefundPolicy = () => {
  return (
    <Container>
      <Title>Return and Refund Policy</Title>
      
      <Section>
        <Heading>1. Introduction</Heading>
        <Text>
          Thank you for shopping with Hadassah Premium. We are committed to ensuring your satisfaction with our products. If, for any reason, you are not entirely satisfied, our Return and Refund Policy provides guidelines on how to proceed.
        </Text>
      </Section>

      <Section>
        <Heading>2. Return Eligibility</Heading>
        <Text>
          To be eligible for a return, items must be unused, in their original packaging, and in the same condition you received them. Returns must be initiated within 30 days of purchase. Please ensure all packaging, labels, and accessories are intact to avoid eligibility issues.
        </Text>
      </Section>

      <Section>
        <Heading>3. Non-Returnable Items</Heading>
        <Text>
          Certain items cannot be returned. This includes perishable goods (such as food items), personalized products, and digital downloads. For health and safety reasons, items such as undergarments and food packaging materials are also non-returnable.
        </Text>
      </Section>

      <Section>
        <Heading>4. Return Process</Heading>
        <Text>
          To initiate a return, please contact our customer support team at <strong>hadassahpremium247@gmail.com</strong> with your order details. We will provide instructions and guide you through the process of sending your item(s) back to us.
        </Text>
      </Section>

      <Section>
        <Heading>5. Refunds</Heading>
        <Text>
          Once your return is received and inspected, we will notify you of the approval or rejection of your refund. Approved refunds will be processed and automatically applied to your original payment method within 7-10 business days.
        </Text>
      </Section>

      <Section>
        <Heading>6. Exchanges</Heading>
        <Text>
          We only replace items if they are defective or damaged upon receipt. If you need an exchange, please contact us to arrange for a replacement at no extra cost.
        </Text>
      </Section>

      <Section>
        <Heading>7. Return Shipping Costs</Heading>
        <Text>
          Customers are responsible for return shipping costs unless the return is due to a defective or incorrect item. Shipping fees for the original order are non-refundable.
        </Text>
      </Section>

      <Section>
        <Heading>8. Contact Us</Heading>
        <Text>
          For further assistance with your return or refund, please reach out to us at <strong>hadassahpremium247@gmail.com</strong>. We’re here to help ensure your experience with Hadassah Premium remains positive.
        </Text>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: rgba(0, 0, 255, 0.6);
  text-align: center;
`;

const Text = styled.p`
  color: #333;
  font-size: 1rem;
  margin: 15px 0;
`;

const Section = styled.div`
  margin: 20px 0;
`;

const Heading = styled.h2`
  color: rgba(0, 0, 255, 0.6);
  font-size: 1.2rem;
`;

export default ReturnRefundPolicy;
