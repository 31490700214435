import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../CSS/OrderSummaryPage.css';
import { Context } from './Context';
import Swal from "sweetalert2"
import { useDispatch } from 'react-redux';
import PaystackPop from "@paystack/inline-js"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { clearCart } from '../Features/Slice';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
    

const OrderSummaryPageDigital = () => {

  const navigate = useNavigate()
    const dispatch = useDispatch()
    // const userInfo = useSelector(state=>state.userInfo)
  const user = useSelector((state) => state.DeliveryDetailDigital2);
  const cart = useSelector((state) => state.cartItemsDigital);
  console.log(cart);
  
  const {loading,setLoading,loading2,setLoading2,dashContent,setDashContent,userAllOrders,setUserAllOrders,
    orderedList,setOrderedList,orderListPostUrl,orderSendEmailUrl
  }=useContext(Context)

  const {setAdminMenu}=useContext(Context)

  

  
  const createOrder = async (orderSummary) => {
    const loadingAlert = Swal.fire({ text: "Processing your order..." });
    Swal.showLoading();
    
    try {
      // Show a loading alert
      
  
      // Make a POST request to the backend
      const response = await axios.post('https://www.hotsalesng.com/api/api4users/create_order.php', orderSummary);
      
      
      Swal.fire({
        icon: 'success',
        title: 'Order Placed Successfully!',
        text: 'Your order has been confirmed. You will receive an email with the order details.',
        allowOutsideClick:false,
    }).then((result) => {
        if (result.isConfirmed) {
            navigate('/');
            // setAdminMenu(1)
        }
    });     

      // Handle success response
      if (response.data.success) {
        
        
    
  
     
        
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Order Failed',
          text: response.data.error || 'An error occurred while processing your order.',
        });
      }
    } catch (error) {
      console.error('Error creating order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while processing your order. Please try again later.',
      });
    } finally {
      // Close the loading alert
    //   Swal.close();
    loadingAlert.close();
    }
  };
  



  const generateOrderRef = () => {
    return 'ORD-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
  };

  const [isChecked,setIsChecked]=useState(false)
  const email = user.email;
  const amount= cart.price;
  const name = user.name;

  const cart2=[cart]



const handleOrderNow2 = async (reference) => {
    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };
  
    const orderSummary = {
      // userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: reference,
      orderRef: generateOrderRef(),
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      address: user.address,
      state: user.state,
      city: user.city,

      cartItems: cart2.map(item => ({
        imageUrl: `https://www.hotsalesng.com/api/uploads/${item.image}`,
        productName: item.productName,
        // quantity: item.quantity,
        price: item.price, // raw price, without formatting
        productId:item.id,
        productUrl:item.url
      })),

      total: amount // unformatted total amount
    };
  
    console.log(orderSummary);
    createOrder(orderSummary);
  
    try {
      const response = await axios.post(`${orderSendEmailUrl}`, {
        buyerEmail: user.email,
        sellerEmail: 'hadassahpremium247@gmail.com', 
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });
  
      if (response.status === 200) {
        // Swal.fire({ icon: "success", text: "Order confirmed, Please check your email for details" });
      } else {
        // Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      console.error(error);
    //   Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
    }
  };
  




  const handleOrderNow = () => {
    // handleLoading();
    if (isChecked) {
    //   handleOrderNow3()
    } else {
      payWithPaystack();
    }
  };

 



  const payWithPaystack = () => {

    Swal.fire({
      text: "Please wait...",
      allowOutsideClick:false,
    })
    Swal.showLoading();
      
 
        const paystack = new PaystackPop();
        paystack.newTransaction({ 
        //   key: "pk_test_60e1f53bba7c80b60029bf611a26a66a9a22d4e4",
          key: "pk_live_afb3375b9a770a5a332904dcf1a26e77c2a5f170",
          amount: amount * 100, // Amount in kobo
          email: user.email,
          firstname: user.firstName,
          lastnme:user.lastName,

          onSuccess: (transaction) => {
            verifyTransaction(transaction.reference)
          },
          onCancel: () => {
            Swal.fire({ icon: "error", text: "Payment cancelled.", showConfirmButton: true });
          },
          onError: (error) => {
            Swal.fire({ icon: "error", text: `Payment failed: ${error.message}`, showConfirmButton: true });
          }
        });
      
    
  };

  
  const verifyTransaction = async (reference) => {
    const loadingAlert = Swal.fire({
      title: "Confirming payment",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });
  
    Swal.showLoading();
  
    try {
      // Include your secret key in headers
      const response = await axios.get(`https://api.paystack.co/transaction/verify/${reference}`, {
        headers: {
        //   Authorization: `Bearer sk_test_2c7610bd616abcaeaf6a002ad8d96f994d8ff723`
          Authorization: `Bearer sk_live_a4b90817cbddf701c55ab1936d43ef666a6dc220`
        }
      });

    
  
      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          text: "Payment successful!",
          showConfirmButton: true,
          timer: 2000
        });
  
        handleOrderNow2(reference);
      } else {
        Swal.fire({
          icon: "error",
          text: `${response.data.message}`,
          showConfirmButton: true
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error.response?.data?.message || 'Error verifying payment!',
        showConfirmButton: true
      });
    } finally {
      loadingAlert.close();
    }
  };
  
  

const [currencyOption,setCurrencyOption]=useState("")

 
 
 const flutterwaveConfig = {
  public_key: "FLWPUBK_TEST-3f4052d4a8b6e027b460756652b193df-X",
  tx_ref: generateOrderRef(),
  amount: amount/1600,
  currency: 'USD',
  payment_options: 'card,banktransfer',
  customer: {
    email: email,
    name: cart.name,
  },
  customizations: {
    title: 'Hadassah Premium',
    description: 'Payment for items in cart',
  },
};

const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

const payWithFlutterWave = () => {
  Swal.fire({
    text: `You are about to pay NGN ${amount}, which is USD ${(amount / 1600).toFixed(2)} in dollars.`,
    icon: "info",
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      handleFlutterPayment({
        callback: (response) => {
          if (response.status === "successful") {
            Swal.fire({ icon: "success", text: "Payment successful!" });
            handleOrderNow2(response.transaction_id);
          }
          closePaymentModal();
        },
        onClose: () => Swal.fire({ icon: "error", text: "Payment cancelled." }),
      });
    } else {
      Swal.fire({ icon: "warning", text: "Payment terminated" });
    }
  });
};



  return (
    <div className="order-summary">
      <h2>Order Summary</h2>
      <div className="user-info">
        <h3>Delivery Information</h3>
        <p><strong>First Name:</strong> {user.firstName}</p>
        <p><strong>Last Name:</strong> {user.lastName}</p>
        <p><strong>Phone:</strong> {user.phone}</p>
        <p><strong>Email:</strong> {user.email}</p>
      </div>
      <div className="cart-info">
        <h3>Item</h3>
        <ul>
          
            <li>
                <img src={`https://www.hotsalesng.com/api/uploads/${cart.image}`} alt="summaryImage"/>
              <p>{cart.productName} ₦ {new Intl.NumberFormat().format(cart.price)}</p>
              <p style={{fontSize:"0.7rem"}}>Product ID: {cart.id}</p>
            </li>
        
        </ul>
      </div>
      <div className="total-info">
        <h3 style={{color:"rgba(0,0,255,0.6"}}>Total</h3>
        <p><strong>Subtotal:</strong> ₦ {new Intl.NumberFormat().format(amount)}</p>
        <p><strong>Grand Total:</strong> ₦{new Intl.NumberFormat().format(amount)}</p>
      </div>





<select className='OrderSummaryPageSelect' onChange={(e)=>setCurrencyOption(e.target.value)}>
  <option >--Select Currency in which you want to pay and click "pay now"--</option>
  <option value="naira">Naira</option>
  <option value="usd">USD</option>
</select>

{currencyOption==="naira"&&<button type="button" onClick={payWithPaystack}>Pay Now</button>}
{currencyOption==="usd"&&<button type="button" onClick={payWithFlutterWave}>Pay Now</button>}
    
    </div>

  );
};

export default OrderSummaryPageDigital;



